import { InMemoryCache, makeVar } from "@apollo/client";

import { OFFER_FULL_FRAGMENT, PUBLIC_OFFER_ID_FRAGMENT } from "@graphQl/fragments/offer";
import { offsetLimitPagination } from "@apollo/client/utilities";
import { TERM_PAYMENT_TERM_RANGE_FRAGMENT } from "@src/graphQl/fragments/paymentTermRangeFragment";

export const currentPeriodIdVar = makeVar("0");
export const currentUserWorkspaceDefaultIdVar = makeVar("0");
export const currentUserWorkspacePeriodDefaultIdVar = makeVar("0");
export const currentProfitabilityIdVar = makeVar(undefined);
export const currentPotentialIdVar = makeVar(undefined);
export const currentProjectionIdVar = makeVar(undefined);
export const currentDistributionIdVar = makeVar(undefined);
export const currentCostBasedIdVar = makeVar("0");
export const currentLeverIdVar = makeVar("0");
export const netProfitStepValueVar = makeVar(0);
export const menuCollapsedVar = makeVar(true);
export const realRevenueVar = makeVar(0);
export const currentPriceModelIdVar = makeVar("0");
export const currentWorkspaceIdVar = makeVar(undefined);

const timeMetricOrder = [
	"oneShot",
	"fiveYear",
	"fourYear",
	"threeYear",
	"twoYear",
	"year",
	"semester",
	"quarter",
	"month",
	"week",
	"day",
	"hour",
];

export const cache = new InMemoryCache({
	possibleTypes: {
		OfferVersionRuleInterface: ["OfferVersionRule", "OfferVersionRuleForRepaymentSchedule"],
	},
	typePolicies: {
		LevelData: {
			merge: true,
		},
		NumericalRange: {
			merge: true,
		},
		DiscountScheduleRange: {
			merge: true,
		},
		ProfitabilityPack: {
			merge: true,
		},
		Pack: {
			merge: true,
		},
		DiscountRuleSection: {
			merge: true,
		},
		DiscountRule: {
			merge: true,
		},
		VariableTimeDiscount: {
			merge: true,
		},
		VariableTimeRange: {
			merge: true,
		},
		VariableTimeRangeDiscount: {
			merge: true,
		},
		VolumeDiscount: {
			merge: true,
		},
		VolumeRange: {
			merge: true,
		},
		EngagementDiscount: {
			merge: true,
		},
		EngagementRange: {
			merge: true,
		},
		PaymentTermRange: {
			merge: true,
		},
		SpecialDiscount: {
			merge: true,
		},
		SpecialRange: {
			merge: true,
		},
		DiscountRuleTemplate: {
			merge: true,
		},
		PriceModel: {
			merge: true,
			fields: {
				isModified: {
					read(currentValue) {
						return currentValue || false;
					},
				},
				// categories: {
				// 	read(currentValue, { readField, cache }) {
				// 		if (currentValue) return currentValue;
				// 		const id = readField("id");
				// 		const res = cache.readQuery({
				// 			query: GET_CATEGORIES_BY_PRICE_MODEL_ID,
				// 			variables: {
				// 				id,
				// 			},
				// 		});
				// 		return [...(res?.getCategoryPriceModelByPriceModelId || [])].sort(
				// 			(categoryA, categoryB) => {
				// 				const indexA = readField("index", categoryA);
				// 				const indexB = readField("index", categoryB);
				// 				return indexA - indexB;
				// 			}
				// 		);
				// 	},
				// },
				// features: {
				// 	read(currentValue, { readField, cache }) {
				// 		if (currentValue) return currentValue;
				// 		const id = readField("id");
				// 		const categories = readField("categories");
				// 		const priceModelFeatureIdsInSandbox = readField(
				// 			"priceModelFeatureIdsInSandbox"
				// 		);
				//
				// 		const priceModelFeatureIds = categories
				// 			?.map(category => category.priceModelFeatureIds)
				// 			.concat(priceModelFeatureIdsInSandbox);
				//
				// 		const features = [];
				// 		priceModelFeatureIds?.forEach(priceModelFeatureId => {
				// 			const priceModelFeature = cache.readFragment({
				// 				id: "PriceModelFeature:" + priceModelFeatureId,
				// 				fragment: PRICE_MODEL_FEATURE_INDEX_FRAGMENT,
				// 				fragmentName: "PriceModelFeatureIndexFragment",
				// 			});
				//
				// 			if (priceModelFeature) features.push(priceModelFeature);
				// 		});
				// 		return features.sort((featureA, featureB) => {
				// 			const indexA = readField("index", featureA);
				// 			const indexB = readField("index", featureB);
				// 			return indexA - indexB;
				// 		});
				// 	},
				// },
				showErrors: {
					read(currentValue) {
						return currentValue || false;
					},
				},
				showPricePointsSection: {
					read(currentValue) {
						return currentValue || false;
					},
				},
				errors(existingErrors, { canRead }) {
					return existingErrors ? existingErrors.filter(canRead) : [];
				},
			},
		},
		OfferProperties: {
			merge: true,
		},
		QuoteConfiguration: {
			merge: true,
		},
		PriceModelCombination: {
			merge: true,
			fields: {
				name: {
					read(_, { toReference, readField }) {
						const priceModelCombinationPropertiesId = readField(
							"priceModelCombinationPropertiesId"
						);
						if (priceModelCombinationPropertiesId) {
							return readField(
								"name",
								toReference({
									__typename: "PriceModelCombinationProperties",
									id: priceModelCombinationPropertiesId,
								})
							);
						}
					},
				},
			},
		},
		Feature: {
			// keyFields: ["id", "isTemplate"],
			merge: true,
			fields: {
				showErrors: {
					read(currentValue) {
						return currentValue || false;
					},
				},
				errors(existingErrors, { canRead }) {
					return existingErrors ? existingErrors.filter(canRead) : [];
				},
				pricingPlans: {
					...offsetLimitPagination(),
					merge(existing = [], incoming, { args }) {
						const { offset = 0 } = args || {};
						const merged = existing?.data ? existing?.data?.slice(0) : [];
						for (let i = 0; i < incoming?.data?.length; ++i) {
							merged[offset + i] = incoming?.data[i];
						}

						return {
							...incoming,
							data: merged,
						};
					},
				},
			},
		},
		PaymentTermDiscount: {
			merge: true,
			fields: {
				paymentTermRange: {
					read(currentValue) {
						return [...(currentValue || [])].sort((a, b) => {
							const paymentTermRangeA = cache.readFragment({
								id: a.__ref,
								fragment: TERM_PAYMENT_TERM_RANGE_FRAGMENT,
							});
							const paymentTermRangeB = cache.readFragment({
								id: b.__ref,
								fragment: TERM_PAYMENT_TERM_RANGE_FRAGMENT,
							});
							return (
								timeMetricOrder.indexOf(paymentTermRangeB?.term) -
								timeMetricOrder.indexOf(paymentTermRangeA?.term)
							);
						});
					},
				},
			},
		},
		Level: {
			merge: true,
			keyFields: ["packId", "featureId"],
		},
		LevelDataPack: {
			merge: true,
			keyFields: ["packId", "levelDataId"],
		},
		VolumeRangeDiscount: {
			merge: true,
			keyFields: ["packId", "volumeRangeId"],
		},
		EngagementRangeDiscount: {
			merge: true,
			keyFields: ["packId", "engagementRangeId"],
		},
		PaymentTermRangeDiscount: {
			merge: true,
			keyFields: ["packId", "paymentTermRangeId"],
		},
		SpecialRangeDiscount: {
			merge: true,
			keyFields: ["packId", "specialRangeId"],
		},
		SpecialDiscountDefaultPosition: {
			keyFields: ["packId", "specialDiscountId"],
		},
		CategoryPriceModelCombination: {
			keyFields: ["priceModelCombinationPropertiesId", "categorizationId"],
		},
		CategoryPackageBuilder: {
			keyFields: ["priceModelId", "categorizationId"],
		},
		Error: {
			keyFields: ["parentId", "parentType", "field"],
		},
		RangeDependency: {
			keyFields: ["requiredId", "desiredId"],
		},
		PublicOffer: {
			keyFields: ["tokenObj", ["token"]],
		},
		PriceModelVPM: {
			keyFields: ["priceModelId"],
		},
		PriceModelDesignAndSetting: {
			merge: true,
			keyFields: ["priceModelId"],
		},
		PriceModelLinkCombination: {
			merge: true,
		},
		Workspace: {
			merge: true,
		},

		Query: {
			fields: {
				getVatsForCurrentWorkspace: {
					read(_, { args, toReference, readField }) {
						const workspaceId = currentWorkspaceIdVar();
						return toReference({
							__typename: "VAT",
							workspaceId,
						});
					},
				},
				getDiscountPricePoint: {
					merge: true,
				},
				getOfferByToken: {
					merge: true,
					read(currentValue, { args, cache }) {
						let offer = null;
						if (!args.id) {
							const publicOffer = cache.readFragment({
								id: cache.identify({
									__typename: "PublicOffer",
									tokenObj: { token: args.token },
								}),
								fragment: PUBLIC_OFFER_ID_FRAGMENT,
							});
							if (!publicOffer) {
								return currentValue;
							}
							offer = cache.readFragment({
								id: cache.identify({
									__typename: "Offer",
									id: publicOffer.id,
								}),
								fragment: OFFER_FULL_FRAGMENT,
								fragmentName: "OfferFullFragment",
								returnPartialData: true,
							});
						} else {
							offer = cache.readFragment({
								id: cache.identify({
									__typename: "Offer",
									id: args.id,
								}),
								fragment: OFFER_FULL_FRAGMENT,
								fragmentName: "OfferFullFragment",
								returnPartialData: true,
							});
						}
						if (!offer?.offerVersions) {
							return currentValue;
						}
						return {
							...offer,
							lastVersion: offer.offerVersions[0],
							__typename: "PublicOffer",
						};
					},
				},
				getCurrentWorkspace: {
					read(_, { args, toReference, readField }) {
						const workspaceId = currentWorkspaceIdVar();
						return toReference({
							__typename: "Workspace",
							id: workspaceId,
						});
					},
				},
				getDiscountRuleByPriceModelId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"discountRule",
							toReference({
								__typename: "PriceModel",
								id: args.priceModelId,
							})
						);
					},
				},
				getDiscountRulesByPriceModelIds: {
					read(_, { args, toReference, readField }) {
						return args.priceModelIds.map(priceModelId =>
							readField(
								"discountRule",
								toReference({
									__typename: "PriceModel",
									id: priceModelId,
								})
							)
						);
					},
				},
				getVolumeDiscountByPriceModelId: {
					read(_, { args, toReference, readField }) {
						const discountRules = readField(
							"discountRule",
							toReference({
								__typename: "PriceModel",
								id: args.id,
							})
						);
						const discountRuleRef = discountRules?.find(discountRule => {
							const volumeDiscount = readField("volumeDiscount", discountRule);
							const unitMetric = readField("unitMetric", volumeDiscount);
							return volumeDiscount && !unitMetric;
						});
						if (!discountRuleRef) return null;
						return readField("volumeDiscount", discountRuleRef);
					},
				},
				getNumericalDiscountsByPriceModelId: {
					read(_, { args, toReference, readField }) {
						const discountRules = readField(
							"discountRule",
							toReference({
								__typename: "PriceModel",
								id: args.id,
							})
						);
						return discountRules?.reduce((volumeDiscounts, discountRule) => {
							const volumeDiscount = readField("volumeDiscount", discountRule);
							const unitMetric = readField("unitMetric", volumeDiscount);
							if (volumeDiscount && unitMetric) {
								volumeDiscounts.push(volumeDiscount);
							}
							return volumeDiscounts;
						}, []);
					},
				},
				getEngagementDiscountByPriceModelId: {
					read(_, { args, toReference, readField }) {
						const discountRules = readField(
							"discountRule",
							toReference({
								__typename: "PriceModel",
								id: args.id,
							})
						);
						return discountRules?.reduce((engagementDiscounts, discountRule) => {
							const engagementDiscount = readField(
								"engagementDiscount",
								discountRule
							);
							if (engagementDiscount) {
								engagementDiscounts.push(engagementDiscount);
							}
							return engagementDiscounts;
						}, []);
					},
				},
				getPaymentTermDiscountByPriceModelId: {
					read(_, { args, toReference, readField }) {
						const discountRules = readField(
							"discountRule",
							toReference({
								__typename: "PriceModel",
								id: args.id,
							})
						);
						return discountRules?.reduce((paymentTermDiscounts, discountRule) => {
							const paymentTermDiscount = readField(
								"paymentTermDiscount",
								discountRule
							);
							if (paymentTermDiscount) {
								paymentTermDiscounts.push(paymentTermDiscount);
							}
							return paymentTermDiscounts;
						}, []);
					},
				},
				getSpecialDiscountsByPriceModelId: {
					read(_, { args, toReference, readField }) {
						const discountRules = readField(
							"discountRule",
							toReference({
								__typename: "PriceModel",
								id: args.id,
							})
						);
						return discountRules?.reduce((specialDiscounts, discountRule) => {
							const specialDiscount = readField("specialDiscount", discountRule);
							if (specialDiscount) {
								specialDiscounts.push(specialDiscount);
							}
							return specialDiscounts;
						}, []);
					},
				},
				getDiscountScheduleRangeById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "DiscountScheduleRange",
							id: args.id,
						});
					},
				},
				getSwitchOptionById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "SwitchOption",
							id: args.id,
						});
					},
				},
				getDiscountRuleById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "DiscountRule",
							id: args.id,
						});
					},
				},
				getDiscountRuleSectionById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "DiscountRuleSection",
							id: args.id,
						});
					},
				},
				getNumericalRangeById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "NumericalRange",
							id: args.id,
						});
					},
				},
				getNumericalRangeByNumericalId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"ranges",
							toReference({
								__typename: "Numerical",
								id: args.id,
							})
						);
					},
				},
				getSelectOptionById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "SelectOption",
							id: args.id,
						});
					},
				},
				getSelectOptionBySelectId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"options",
							toReference({
								__typename: "Select",
								id: args.id,
							})
						);
					},
				},
				getPriceModelLinkCombinationByPriceModelCombinationId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"priceModelLinkCombination",
							toReference({
								__typename: "PriceModelCombination",
								id: args.priceModelCombinationId,
							})
						);
					},
				},
				getQuoteConfigurationByPriceModelCombinationId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"quoteConfiguration",
							toReference({
								__typename: "PriceModelCombination",
								id: args.priceModelCombinationId,
							})
						);
					},
				},
				getPriceModelCombinationById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "PriceModelCombination",
							id: args.id,
						});
					},
				},
				getDependencyById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "Dependency",
							id: args.id,
						});
					},
				},
				getRangeDependency: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "RangeDependency",
							desiredId: args.desiredId,
							requiredId: args.requiredId,
						});
					},
				},
				getPaginatedPriceModelCombinations: offsetLimitPagination(args => {
					let key = "";
					key = key.concat(
						args?.filters?.search?.toString() ?? "noSearch",
						"+",
						args?.filters?.languages?.[0]?.toString() ?? "noLanguage"
					);
					key += "+" + args?.filters?.categories?.join("+");
					return key;
				}),
				features: {
					...offsetLimitPagination((args, context) => {
						let key = "";
						if (args?.sort) {
							key += "sort:{";
							key += Object.keys(args?.sort).reduce((prev, sortKey, index) => {
								if (Array.isArray(args.sort[sortKey])) {
									return (
										prev +
										sortKey +
										":[" +
										args.sort[sortKey].reduce((prev, sort, index) => {
											if (index > 0) {
												prev += ",";
											}
											return prev + sort.id + ":" + sort.sort.sort;
										}, "") +
										"]"
									);
								}
								if (index > 0) {
									prev += "+";
								}
								return prev + sortKey + ":" + args.sort[sortKey].sort;
							}, "");
							key += "}";
							key += args?.filters ? "," : "";
						}
						if (args?.filters) {
							key += "filter:{";
							key += Object.keys(args?.filters).reduce((prev, filterKey, index) => {
								if (index > 0) {
									prev += "+";
								}
								switch (filterKey) {
									case "createdBy":
									case "updatedBy":
									case "format":
										return prev + args.filters[filterKey].toString();
									case "search":
										return prev + args.filters[filterKey];
									case "categories":
										return prev + args.filters[filterKey].join("+");
									default:
										return prev;
								}
							}, "");
							key += "}";
						}
						if (context?.variables?.priceModelId) {
							key += `+${context?.variables?.priceModelId}`;
						}
						return key;
					}),
					read(existing, { args: { offset, limit }, variables: { priceModelId } }) {
						if (priceModelId) {
							return existing;
						}

						function hasEmptySlot(arr) {
							for (let i = 0; i < arr.length; i++) {
								if (!(i in arr)) {
									return true;
								}
							}
							return false;
						}

						if (!!existing) {
							const existingWithOffset = existing.slice(offset, offset + limit);
							if (existingWithOffset?.length) {
								if (hasEmptySlot(existingWithOffset)) {
									return undefined;
								}
								return existingWithOffset;
							}
						}
						return undefined;
					},
				},

				getPriceModelLinkCombinationById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "PriceModelLinkCombination",
							id: args.id,
						});
					},
				},
				getTokenById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "Token",
							id: args.id,
						});
					},
				},
				getPriceModelCombinationsByPriceModelId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"priceModelCombinations",
							toReference({
								__typename: "PriceModel",
								id: args.priceModelId,
							})
						);
					},
				},
				getSelectById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "Select",
							id: args.id,
						});
					},
				},
				getSwitchById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "Switch",
							id: args.id,
						});
					},
				},
				getSwitchOptionsBySwitchId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"options",
							toReference({
								__typename: "Switch",
								id: args.id,
							})
						);
					},
				},
				getNumericalById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "Numerical",
							id: args.id,
						});
					},
				},
				getLevelByFeatureIdAndPackId: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "Level",
							packId: args.packId,
							featureId: args.featureId,
						});
					},
				},
				getPriceModelFeatureByIds: {
					read(value, { args, toReference, canRead, readField }) {
						if (value) return value;
						if (args) {
							const refs = args.ids.map(id =>
								toReference({ __typename: "PriceModelFeature", id })
							);
							if (refs.find(ref => !canRead(ref))) {
								return undefined;
							} else {
								refs.sort((refA, refB) => {
									const indexRefA = readField("index", refA);
									const indexRefB = readField("index", refB);

									return indexRefA - indexRefB;
								});
								return refs;
							}
						}
						return undefined;
					},
				},
				getFeatureById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "Feature",
							id: args.id,
						});
					},
				},
				getPriceModelFeatureById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "PriceModelFeature",
							id: args.id,
						});
					},
				},
				getFeatureTemplateById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "Feature",
							id: args.id,
						});
					},
				},
				getTemplateById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "DiscountRuleTemplate",
							id: args.id,
						});
					},
				},
				getPriceModelById: {
					merge: true,
					read(_, { args, toReference }) {
						return toReference({
							__typename: "PriceModel",
							id: args.id,
						});
					},
				},
				getPacksByPriceModelId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"packs",
							toReference({
								__typename: "PriceModel",
								id: args.id,
							})
						);
					},
				},
				getCategoriesByCategorizationId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"categories",
							toReference({
								__typename: "CategorizationNew",
								id: args.id,
							})
						);
					},
				},
				getWebhookById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "Webhook",
							id: args.id,
						});
					},
				},
				getPackById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "Pack",
							id: args.id,
						});
					},
				},
				getPacksByIds: {
					read(_, { args, toReference, readField }) {
						return args.ids.map(packId =>
							toReference({
								__typename: "Pack",
								id: packId,
							})
						);
					},
				},
				getSpecialDiscountById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "SpecialDiscount",
							id: args.id,
						});
					},
				},
				getVolumeDiscountById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "VolumeDiscount",
							id: args.id,
						});
					},
				},
				getEngagementDiscountById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "EngagementDiscount",
							id: args.id,
						});
					},
				},
				getPaymentTermDiscountById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "PaymentTermDiscount",
							id: args.id,
						});
					},
				},
				getPaymentTermRangeById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "PaymentTermRange",
							id: args.id,
						});
					},
				},
				getPaymentTermRangeDiscountByPaymentTermRangeIdAndPackId: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "PaymentTermRangeDiscount",
							packId: args.packId,
							paymentTermRangeId: args.paymentTermRangeId,
						});
					},
				},
				getSpecialRangeById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "SpecialRange",
							id: args.id,
						});
					},
				},
				getSpecialRangeDiscountBySpecialRangeId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"specialDiscountRange",
							toReference({
								__typename: "SpecialRange",
								id: args.specialRangeId,
							})
						);
					},
				},
				getSpecialDiscountDefaultPositionBySpecialDiscountIdAndPackId: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "SpecialDiscountDefaultPosition",
							packId: args.packId,
							specialDiscountId: args.specialDiscountId,
						});
					},
				},
				getSpecialRangeDiscountBySpecialRangeIdAndPackId: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "SpecialRangeDiscount",
							packId: args.packId,
							specialRangeId: args.specialRangeId,
						});
					},
				},
				getEngagementRangeById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "EngagementRange",
							id: args.id,
						});
					},
				},
				getEngagementRangeDiscountByEngagementRangeIdAndPackId: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "EngagementRangeDiscount",
							packId: args.packId,
							engagementRangeId: args.engagementRangeId,
						});
					},
				},
				getVolumeRangeById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "VolumeRange",
							id: args.id,
						});
					},
				},
				getVolumeRangeByVolumeDiscountId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"volumeRange",
							toReference({
								__typename: "VolumeDiscount",
								id: args.id,
							})
						);
					},
				},
				getPriceModelCombinationQuoteFieldById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "PriceModelCombinationQuoteField",
							id: args.id,
						});
					},
				},
				getPriceModelCombinationQuoteFieldsByPriceModelCombinationId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"quoteFields",
							toReference({
								__typename: "PriceModelCombination",
								id: args.priceModelCombinationId,
							})
						);
					},
				},
				getVolumeRangeDiscountByVolumeRangeIdAndPackId: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "VolumeRangeDiscount",
							packId: args.packId,
							volumeRangeId: args.volumeRangeId,
						});
					},
				},
				getCategorizationNewById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "CategorizationNew",
							id: args.id,
						});
					},
				},
				getCategoryNewById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "CategoryNew",
							id: args.id,
						});
					},
				},
				getSelectedCategoryByPriceModelCombinationIdAndCategorizationId: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "CategoryPriceModelCombination",
							priceModelCombinationPropertiesId:
								args.priceModelCombinationPropertiesId,
							categorizationId: args.categorizationId,
						});
					},
				},
				getSelectedCategoryByPriceModelIdAndCategorizationId: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "CategoryPackageBuilder",
							priceModelId: args.priceModelId,
							categorizationId: args.categorizationId,
						});
					},
				},
				getProfitabilityThresholdByPriceModelCombinationId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"profitabilityThreshold",
							toReference({
								__typename: "PriceModelCombination",
								id: args.priceModelCombinationId,
							})
						);
					},
				},
				getProfitabilityThresholdRangeByProfitabilityThresholdId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"profitabilityThresholdRanges",
							toReference({
								__typename: "ProfitabilityThreshold",
								id: args.profitabilityThresholdId,
							})
						);
					},
				},
				getProfitabilityThresholdRangeById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "ProfitabilityThresholdRange",
							id: args.id,
						});
					},
				},
				getErrorsByPackId: {
					read(_, { args, toReference, readField }) {
						const packErrors = readField(
							"errors",
							toReference({
								__typename: "Pack",
								id: args.packId,
							})
						);
						return packErrors;
					},
				},
				getErrorsByFeatureTemplateId: {
					read(_, { args, toReference, readField }) {
						const featureTemplateErrors = readField(
							"errors",
							toReference({
								__typename: "Feature",
								id: args.featureId,
								isTemplate: true,
							})
						);
						return featureTemplateErrors;
					},
				},
				getErrorsByPriceModelId: {
					read(_, { args, toReference, readField }) {
						const priceModelErrors = readField(
							"errors",
							toReference({
								__typename: "PriceModel",
								id: args.priceModelId,
							})
						);
						const packs = readField(
							"packs",
							toReference({
								__typename: "PriceModel",
								id: args.priceModelId,
							})
						);
						const packsErrors =
							packs?.map(pack => {
								if (pack.__ref) {
									return readField("errors", toReference(pack.__ref));
								}
								return pack?.errors;
							}) || [];
						const features = readField(
							"features",
							toReference({
								__typename: "PriceModel",
								id: args.priceModelId,
							})
						);
						const levelErrors = features?.map(feature => {
							// const levels = readField("level", toReference(feature.__ref));
							if (!feature) return [];
							const levels = feature.level;
							return levels?.map(level => {
								if (level.__ref) {
									return readField("errors", toReference(level.__ref));
								}
								return level?.errors;
							});
						});

						return packsErrors
							.flat()
							.concat(priceModelErrors)
							.concat(levelErrors?.flat(2));
					},
				},
				getErrorsByFeatureId: {
					read(_, { args, toReference, readField }) {
						const featureErrors = readField(
							"errors",
							toReference({
								__typename: "Feature",
								id: args.featureId,
								isTemplate: false,
							})
						);
						return featureErrors;
					},
				},
				getFeaturesByCategory: {
					...offsetLimitPagination(["id", "priceModelId"]),
					read(features, { readField }) {
						if (!features) return features;
						return [...(features || [])]?.sort((a, b) => {
							const indexA = readField("index", a);
							const indexB = readField("index", b);
							return indexA - indexB;
						});
					},
				},
				getLevelDataById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "LevelData",
							id: args.id,
						});
					},
				},
				getCategoryPriceModelById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "CategoryPriceModel",
							id: args.id,
						});
					},
				},
				getPackAnalysisById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "PackAnalysis",
							id: args.id,
						});
					},
				},
				getPriceModelDesignAndSettingsByPriceModelId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"designAndSettings",
							toReference({
								__typename: "PriceModel",
								id: args.priceModelId,
							})
						);
					},
				},
				getOldColumnById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "OldColumn",
							id: args.id,
						});
					},
				},
				getLevelDataByPriceModelFeatureId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"levelData",
							toReference({
								__typename: "PriceModelFeature",
								id: args.priceModelFeatureId,
							})
						);
					},
				},
				getOfferById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "Offer",
							id: args.id,
						});
					},
				},
				getOfferVersionById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "OfferVersion",
							id: args.id,
						});
					},
				},
				getOfferVersionQuoteById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "OfferVersionQuote",
							id: args.id,
						});
					},
				},
				getOfferVersionAccessById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "OfferVersionAccess",
							id: args.id,
						});
					},
				},
				getOfferVersionRuleById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "OfferVersionRule",
							id: args.id,
						});
					},
				},
				getOfferEmailById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "OfferEmail",
							id: args.id,
						});
					},
				},
				getPriceModelCombinationPropertiesById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "PriceModelCombinationProperties",
							id: args.id,
						});
					},
				},
				getOfferVersionsByOfferId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"offerVersions",
							toReference({
								__typename: "Offer",
								id: args.offerId,
							})
						);
					},
				},
				getOfferVersionQuotesByOfferVersionId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"offerVersionQuotes",
							toReference({
								__typename: "OfferVersion",
								id: args.offerVersionId,
							})
						);
					},
				},
				getOfferVersionRulesByOfferVersionId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"offerVersionRules",
							toReference({
								__typename: "OfferVersion",
								id: args.offerVersionId,
							})
						);
					},
				},
				getLevelDataPackByLevelDataIdAndPackId: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "LevelDataPack",
							levelDataId: args.levelDataId,
							packId: args.packId,
						});
					},
				},
				getTermsAndConditions: {
					read(termsAndConditions, { toReference, readField }) {
						return readField(
							"termsAndConditions",
							toReference({
								__typename: "Workspace",
								id: termsAndConditions.workspaceId,
							})
						);
					},
				},
				getSurveyById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "Survey",
							id: args.id,
						});
					},
				},
				getSegmentingQuestionById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "SegmentingQuestion",
							id: args.id,
						});
					},
				},
				getVolumeDiscountLinkById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "VolumeDiscountLink",
							id: args.id,
						});
					},
				},
				getOfferContactsByOfferId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"offerContacts",
							toReference({
								__typename: "Offer",
								id: args.id,
							})
						);
					},
				},
				getOfferPropertiesByOfferId: {
					read(_, { args, toReference, readField }) {
						if (!args.isDraft) {
							return readField(
								"offerProperties",
								toReference({
									__typename: "Offer",
									id: args.offerId,
								})
							);
						}
						return readField(
							"ROOT_QUERY",
							toReference({
								__typename: "getOfferPropertiesByOfferId",
								offerId: args.offerId,
								isDraft: true,
							})
						);
					},
				},
				getOfferPropertiesByPriceModelCombinationId: {
					read(_, { args, toReference, readField }) {
						return readField(
							"offerProperties",
							toReference({
								__typename: "PriceModelCombination",
								id: args.priceModelCombinationId,
							})
						);
					},
				},
				getUserById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "User",
							id: args.id,
						});
					},
				},
				getRoleById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: "Role",
							id: args.id,
						});
					},
				},
				realRevenue: {
					read() {
						return realRevenueVar();
					},
				},
				currentPeriodId: {
					read() {
						return currentPeriodIdVar();
					},
				},
				currentUserWorkspaceDefaultId: {
					read() {
						return currentUserWorkspaceDefaultIdVar();
					},
				},
				currentUserWorkspacePeriodDefaultId: {
					read() {
						return currentUserWorkspacePeriodDefaultIdVar();
					},
				},
				currentProfitabilityId: {
					read() {
						return currentProfitabilityIdVar();
					},
				},
				currentPotentialId: {
					read() {
						return currentPotentialIdVar();
					},
				},
				currentProjectionId: {
					read() {
						return currentProjectionIdVar();
					},
				},
				currentDistributionId: {
					read() {
						return currentDistributionIdVar();
					},
				},
				currentCostBasedId: {
					read() {
						return currentCostBasedIdVar();
					},
				},
				currentLeverId: {
					read() {
						return currentLeverIdVar();
					},
				},
				netProfitStepValue: {
					read() {
						return netProfitStepValueVar();
					},
				},
				menuCollapsed: {
					read() {
						return menuCollapsedVar();
					},
				},
				currentPriceModelId: {
					read() {
						return currentPriceModelIdVar();
					},
				},
				getPaginatedPriceModels: offsetLimitPagination(args => {
					let key = "";
					key = key.concat(
						args?.filters?.search?.toString() ?? "noSearch",
						"+",
						args?.filters?.languages?.[0]?.toString() ?? "noLanguage"
					);
					key += "+" + args?.filters?.categories?.join("+");
					key += "+" + args.filters?.currency?.join("+");
					return key;
				}),
			},
		},
	},
});
